<template>
  <v-row class="mb-4">
    <v-col cols="12" v-if="isLoading">
      <v-progress-circular indeterminate color="primary" />
    </v-col>

    <v-col cols="12" v-if="!isLoading">
      <v-fade-transition>
        <v-row no-gutters justify="center">
          <v-col cols="12" class="mb-4">
            <v-img :src="imagePreview" />
          </v-col>

          <v-col cols="auto" v-if="!showUploadForm">
            <BaseButton color="error" iconLeft iconName="far fa-trash" @click="deleteImage">
              Bild löschen
            </BaseButton>
          </v-col>

          <v-col cols="12" class="mb-n3" align-self="center" v-if="showUploadForm">
            <BaseFileInput
              v-model="imageValue"
              label="FORM_ELEMENTS.IMAGE.LABEL"
              accept="image/*"
              :rules="this.maxFileSize(2048)"
            />
          </v-col>
        </v-row>
      </v-fade-transition>
    </v-col>
  </v-row>
</template>

<script>
import ImageUri from "@helper/ImageUri";
import formValidation from "@mixins/formValidation";
import defaultImage from "@/assets/img/default-image-upload.jpg";

export default {
  name: "ImageUpload",

  mixins: [formValidation],

  data() {
    return {
      isLoading: false,
      currentImage: null,
    };
  },

  props: {
    value: {
      type: undefined,
      required: true,
    },

    mainImageId: {
      type: undefined,
      required: false,
      default: null,
    },
  },

  computed: {
    imageValue: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      },
    },

    imagePreview() {
      if (this.imageValue) return URL.createObjectURL(new Blob([this.imageValue]));
      if (this.currentImage) return this.currentImage;

      return defaultImage;
    },

    showUploadForm() {
      return !this.currentImage;
    },
  },

  methods: {
    deleteImage() {
      this.currentImage = null;
      this.$emit("delete");
    },

    async retrieveImageData(mainImageId) {
      if (!this.mainImageId) return;
      this.isLoading = true;
      const imgId = mainImageId || this.mainImageId;
      try {
        if (imgId) {
          const { data } = await this.$axios.images.invoke({ imageId: imgId });

          this.currentImage = new ImageUri(data.imageData).create();
        } else {
          this.isLoading = false;
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },

  async mounted() {
    await this.retrieveImageData();
  },
};
</script>
