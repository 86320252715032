<template>
  <ConfirmationDialog
    title="SEMINAR_MANAGEMENT.SEMINAR_SPEAKERS.UPDATE.TITLE"
    tooltip="SEMINAR_MANAGEMENT.SEMINAR_SPEAKERS.UPDATE.TOOLTIP"
    labelCancel="SEMINAR_MANAGEMENT.SEMINAR_SPEAKERS.UPDATE.CANCEL"
    labelConfirm="SEMINAR_MANAGEMENT.SEMINAR_SPEAKERS.UPDATE.CONFIRM"
    icon="far fa-pencil"
    v-model="showDialog"
    tertiary
    dialogWidth="640px"
    color="primary"
    @confirm="save"
    @cancel="resetForm"
    :loading="isLoading"
    :showContent="!showContent"
  >
    <v-form v-on:submit.prevent ref="form" v-model="formValidation" lazy-validation>
      <SeminarSpeakerForm v-model="seminarSpeaker" @deleteImage="deleteImage = true" />
    </v-form>
  </ConfirmationDialog>
</template>

<script>
import ConfirmationDialog from "@components/ConfirmationDialog/";
import SeminarSpeakerForm from "@views/Restricted/SeminarManagement/SeminarSpeakers/partials/SeminarSpeakerForm.vue";
import formValidation from "@mixins/formValidation";
import { mapActions } from "vuex";

export default {
  name: "UpdateSeminarSpeaker",

  mixins: [formValidation],

  components: {
    ConfirmationDialog,
    SeminarSpeakerForm,
  },

  props: {
    seminarSpeakerId: {
      type: Number,
      required: true,
    },
  },

  watch: {
    showDialog(value) {
      if (!value) return;
      this.showSeminarSpeaker();
    },
  },

  data() {
    return {
      showDialog: false,
      showContent: false,
      seminarSpeaker: this.initSeminarSpeaker(),
      isLoading: false,
      deleteImage: false,
    };
  },

  computed: {
    payload() {
      return {
        seminarSpeakerId: this.seminarSpeakerId,
        ...this.seminarSpeaker.data,
      };
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    async storeSeminarSpeakerImage() {
      try {
        await this.$axios.seminarManagement.storeSeminarSpeakerImage({
          seminarSpeakerId: this.seminarSpeakerId,
          image: this.seminarSpeaker.image,
        });

        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async deleteSeminarSpeakerImage() {
      try {
        await this.$axios.seminarManagement.deleteSeminarSpeakerImage({
          mainImageId: this.seminarSpeaker.data.mainImageId,
        });

        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    },

    save() {
      this.validate();

      this.$nextTick(async () => {
        if (this.formValidation) {
          try {
            await this.$axios.seminarManagement.updateSeminarSpeaker(this.payload);

            if (this.seminarSpeaker.image) {
              await this.storeSeminarSpeakerImage();
            }

            if (this.deleteImage) {
              await this.deleteSeminarSpeakerImage();
            }

            this.showDialog = false;

            this.resetForm();

            this.notify({
              type: "success",
              message: "SEMINAR_MANAGEMENT.SEMINAR_SPEAKERS.UPDATE.SUCCESS",
            });

            this.$emit("reload");
          } catch (err) {
            console.error(err);
            this.setValidationErrors(err);
          } finally {
            this.isLoading = false;
          }
        }
      });
    },

    async showSeminarSpeaker() {
      this.showContent = true;

      try {
        const { data } = await this.$axios.seminarManagement.showSeminarSpeaker({
          seminarSpeakerId: this.seminarSpeakerId,
        });

        this.seminarSpeaker.data = data.data;
        this.seminarSpeaker.data.seminarFieldOfActionIds = data.data.seminarFieldOfActions.map(
          ({ id }) => id
        );
      } finally {
        this.showContent = false;
      }
    },

    resetForm() {
      this.deleteImage = false;
      this.seminarSpeaker = this.initSeminarSpeaker();
      this.resetValidation();
    },

    initSeminarSpeaker() {
      return {
        data: {
          vitaText: null,
          mainImageId: null,
          seminarFieldOfActionIds: null,
        },
        image: null,
      };
    },
  },
};
</script>
