<template>
  <div class="mt-8">
    <v-row>
      <v-col cols="12" class="mb-n3">
        <BaseHeadline size="4">
          {{ $t("SEMINAR_MANAGEMENT.SEMINAR_SPEAKERS.FORM.TITLES.INFORMATION") }}
        </BaseHeadline>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="mb-n3">
        <BaseTextArea
          v-model="formValue.data.vitaText"
          label="SEMINAR_MANAGEMENT.SEMINAR_SPEAKERS.FORM.LABELS.VITA"
        />
      </v-col>

      <v-col cols="12" class="mb-n3">
        <SeminarFieldOfActionSelect v-model="formValue.data.seminarFieldOfActionIds" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="mb-n3">
        <BaseHeadline size="4">
          {{ $t("SEMINAR_MANAGEMENT.SEMINAR_SPEAKERS.FORM.TITLES.IMAGE") }}
        </BaseHeadline>
      </v-col>

      <v-col cols="12" class="mb-n3">
        <ImageUpload
          v-model="formValue.image"
          :mainImageId="formValue.data.mainImageId"
          @delete="$emit('deleteImage')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SeminarFieldOfActionSelect from "@components/FormElements/SeminarFieldOfActionSelect.vue";
import ImageUpload from "@components/FormElements/ImageUpload.vue";

export default {
  name: "SeminarSpeakerForm",

  components: {
    SeminarFieldOfActionSelect,
    ImageUpload,
  },

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({
        data: {
          vitaText: null,
          mainImageId: null,
          seminarFieldOfActionIds: null,
        },
        image: null,
      }),
    },
  },

  computed: {
    hasImage() {
      return !!this.formValue.data.mainImageId && !!this.formValue.image;
    },

    formValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
