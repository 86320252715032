<template>
  <v-data-table
    :headers="headers"
    :items="seminarSpeakers"
    v-bind="$attrs"
    v-on="$listeners"
    item-key="id"
  >
    <template #[`item.actions`]="{ item }">
      <v-row no-gutters>
        <v-spacer />

        <v-col cols="auto" v-if="hasPermission({ permissionNames: ['seminar_speaker.update'] })">
          <UpdateSeminarSpeaker :seminarSpeakerId="item.id" />
        </v-col>
      </v-row>
    </template>
  </v-data-table>
</template>

<script>
import UpdateSeminarSpeaker from "@views/Restricted/SeminarManagement/SeminarSpeakers/Update";
import hasPermission from "@mixins/hasPermission";

export default {
  name: "SeminarSpeakersTable",

  mixins: [hasPermission],

  components: {
    UpdateSeminarSpeaker,
  },

  props: {
    seminarSpeakers: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t("SEMINAR_MANAGEMENT.SEMINAR_SPEAKERS.LIST.TABLE.HEADER.FIRST_NAME"),
          value: "firstName",
          sortable: true,
        },

        {
          text: this.$t("SEMINAR_MANAGEMENT.SEMINAR_SPEAKERS.LIST.TABLE.HEADER.LAST_NAME"),
          value: "lastName",
          sortable: true,
        },

        {
          text: this.$t("SEMINAR_MANAGEMENT.SEMINAR_SPEAKERS.LIST.TABLE.HEADER.NAV_NUMBER"),
          value: "navisionNumber",
          sortable: true,
        },

        {
          value: "actions",
          align: "end",
          sortable: false,
        },
      ];
    },
  },
};
</script>
