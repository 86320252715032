<template>
  <ModuleSubPage fullWidth title="SEMINAR_MANAGEMENT.SEMINAR_SPEAKERS.TITLE">
    <BaseHasPermission :permissionNames="['seminar_speakers.read']">
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <v-spacer />
            <v-col cols="12" md="5" lg="4" xl="3">
              <BaseTableSearchField
                v-model="filter.search"
                @triggerSearch="listSeminarSpeakers"
                :loading="isLoading"
                :disabled="isLoading"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <SeminarSpeakersTable
            :seminarSpeakers="seminarSpeakers"
            :loading="isLoading"
            :options.sync="options"
            :server-items-length="meta.total"
            @reload="listSeminarSpeakers"
          />
        </v-card-text>
      </v-card>
    </BaseHasPermission>
  </ModuleSubPage>
</template>

<script>
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";
import SeminarSpeakersTable from "@views/Restricted/SeminarManagement/SeminarSpeakers/List/partials/SeminarSpeakersTable.vue";

export default {
  name: "ManagementSeminarSpeakers",

  components: {
    ModuleSubPage,
    SeminarSpeakersTable,
  },

  data() {
    return {
      isLoading: true,
      seminarSpeakers: [],
      options: {
        page: 1,
        perPage: 15,
      },

      meta: {
        total: 0,
      },

      filter: {
        search: null,
      },
    };
  },

  watch: {
    options: {
      deep: true,
      handler() {
        this.listSeminarSpeakers();
      },
    },
  },

  methods: {
    async listSeminarSpeakers() {
      this.isLoading = true;

      try {
        const { data } = await this.$axios.seminarManagement.listSeminarSpeakers({
          ...this.options,
          ...this.filter,
          perPage: this.options.perPage === -1 ? 0 : this.options.perPage,
        });
        this.seminarSpeakers = data.data;
        this.meta = data.meta;
        if (this.options.perPage === -1) {
          this.meta = {
            total: data.data.length,
          };
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },

  created() {
    this.listSeminarSpeakers();
  },
};
</script>
